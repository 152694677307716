import { render, staticRenderFns } from "./InfoModal.vue?vue&type=template&id=7e098e80"
import script from "./InfoModal.vue?vue&type=script&lang=js"
export * from "./InfoModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiModal: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiModal.vue').default})
