import { ref, useContext } from '@nuxtjs/composition-api';
import useRecaptcha from '~/composables/useRecaptcha';

const useForm = (options?: { captcha: boolean }) => {
  const defaultOptions = { captcha: true };
  const mergedOptions = { ...defaultOptions, ...options };

  const { $axios } = useContext();
  const recaptcha = useRecaptcha();
  const formSuccess = ref(false);
  const formPending = ref(false);

  const submit = async (formData: Record<string, any>, formId?: string) => {
    formPending.value = true;
    try {
      const token = recaptcha.getToken();
      const headers = mergedOptions.captcha ? { 'Captcha-Token': token } : {};

      // TODO: replace with $api upon upgrade CMS JS SDK completion https://jira.asbis.app/browse/AB-380
      const response = await $axios.post(
        `/api/forms${formId ? `/${formId}` : ''}`,
        formData,
        {
          headers,
        },
      );

      formSuccess.value = true;

      return response;
    } finally {
      recaptcha.reset();
      formPending.value = false;
    }
  };

  return {
    formSuccess,
    formPending,
    submit,
    recaptcha,
  };
};

export default useForm;
