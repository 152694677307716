
import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  useStore,
  useContext,
  watch,
  useRoute,
} from '@nuxtjs/composition-api';

import useForm from '~/composables/useForm';
import useCountries from '~/composables/useCountries';
import useModalDialog from '~/composables/useModalDialog';

interface ConsultationForm {
  name: string;
  email: string;
  phone: string;
  country: string;
  message: string;
  personalData: boolean;
  marketing: boolean;
}
const FORM_DEFAULTS = {
  name: '',
  email: '',
  phone: '',
  country: '',
  message: '',
  personalData: false,
  marketing: false,
};

const stringifyBoolean = (v: any) =>
  typeof v !== 'boolean' ? v : (v && 'yes') || 'no';

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const config = store.getters['config/getConfig'];
    const lang: string = store.getters['config/getActiveLanguage'];
    const form = ref();
    const { formSuccess, formPending, submit, recaptcha } = useForm();
    const { $gtm, nuxtState } = useContext();
    const {
      isOpen: infoModalIsOpen,
      open: openInfoModal,
      close: closeInfoModal,
    } = useModalDialog('info');

    const formData = reactive<ConsultationForm>({
      ...FORM_DEFAULTS,
    });

    const clearForm = () => {
      Object.assign(formData, FORM_DEFAULTS);

      nextTick(() => form.value.reset());
    };
    const formStartEventSent = ref(false);
    const { getCountriesList } = useCountries();
    const countries = getCountriesList();

    /* eslint-disable */
    const getUtms = () => {
      const { utm_medium, utm_campaign, utm_term, utm_content, utm_source } =
        route.value.query;
      return {
        'utm_medium|1553334': utm_medium || '',
        'utm_campaign|1553336': utm_campaign || '',
        'utm_term|1562540': utm_term || '',
        'utm_content|1562542': utm_content || '',
        'utm_source|1553332': utm_source || config.domain,
      };
    };
    /* eslint-enable */
    const createFormData = (formState: ConsultationForm) => ({
      name: formState.name,
      'email|859532': formState.email,
      'phone|859530': formState.phone,
      country: formState.country,
      'message|1562532': formState.message,
      personal_data: formState.personalData,
      marketing: formState.marketing,

      lang,
      'source_url|1273202': window.location.href,
      ...getUtms(),
    });

    const submitForm = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries({
            ...createFormData(formData),
          })
            .map(([k, v]) => [k, stringifyBoolean(v)])
            .filter(([, v]) => v),
        );

        await submit(payload, nuxtState.config.consultFormUuid);
        $gtm.push({
          event: 'leadform_sent',
        });
        openInfoModal();
        clearForm();
      } catch (error) {
        console.error('Error submitting form', error);
      }
    };

    const pushFormStartEvent = () => {
      if (!formStartEventSent.value) {
        $gtm.push({
          event: 'form_start',
        });
      }
      formStartEventSent.value = true;
    };

    watch(formData, () => {
      pushFormStartEvent();
    });

    return {
      form,
      formData,
      countries,
      formSuccess,
      formPending,
      submitForm,
      recaptcha,
      infoModalIsOpen,
      closeInfoModal,
    };
  },
});
