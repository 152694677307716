import type { Ref, UnwrapRef } from 'vue';
import { useCdnImg } from '@webplatform/cdn-image';
import { computed } from 'vue';

export function getAssetUrl(asset?: string, modifiers = {}): string | null {
  const { generateUrl } = useCdnImg();

  if (!asset) return null;

  return generateUrl(asset, modifiers);
}

export function useMediaAsset(
  asset: Ref<string>,
  modifiers = {},
): Ref<UnwrapRef<string>> | Ref<UnwrapRef<null>> {
  return computed(() => getAssetUrl(asset.value, modifiers));
}
