export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    if (!to || !from) return next();

    const whitelist = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
    ];
    const persistedQuery = Object.fromEntries(
      Object.entries(from.query).filter(([key]) => whitelist.includes(key)),
    );
    const newQuery = { ...persistedQuery, ...to.query };

    if (JSON.stringify(to.query) !== JSON.stringify(newQuery)) {
      return next({ path: to.path, query: newQuery });
    }

    return next();
  });
};
