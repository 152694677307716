import { render, staticRenderFns } from "./ProductVideo.vue?vue&type=template&id=42231275&scoped=true"
import script from "./ProductVideo.vue?vue&type=script&lang=js"
export * from "./ProductVideo.vue?vue&type=script&lang=js"
import style0 from "./ProductVideo.vue?vue&type=style&index=0&id=42231275&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42231275",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiIcon.vue').default})
