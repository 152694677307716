// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../work/modules/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../work/modules/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Corsa_Grotesk/corsa-grotesk-400.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Corsa_Grotesk/corsa-grotesk-700.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Corsa_Grotesk/corsa-grotesk-900.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/Segoe/Segoe UI.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/Segoe/Segoe UI.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/Segoe/Segoe UI Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../fonts/Segoe/Segoe UI Bold.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:\"Corsa Grotesk\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"Corsa Grotesk\";font-style:normal;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"Corsa Grotesk\";font-style:normal;font-weight:900;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:Segoe;font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\")}@font-face{font-display:swap;font-family:Segoe;font-style:normal;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff\")}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
