
import { computed, defineComponent } from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';
import { getAssetUrl } from '~/composables/useMediaAsset';

export default defineComponent({
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isBiggerSm } = useBreakpoint('sm');
    const { isBiggerThanBreakpoint: isBiggerLg } = useBreakpoint('lg');

    const similarProducts = computed(() =>
      props?.product?.similar_products?.[0]?.items
        .filter((item) => item.active)
        .map((item) => ({
          ...item,
          link: `/product/${item.slug}`,
        })),
    );

    const backgroundImage = computed(() =>
      getAssetUrl(
        !isBiggerSm.value && props.data?.backgroundImage?.mobile
          ? props.data?.backgroundImage?.mobile
          : props.data?.backgroundImage?.desktop,
      ),
    );

    return {
      isBiggerSm,
      isBiggerLg,
      backgroundImage,
      similarProducts,
    };
  },
});
