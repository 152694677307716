
import {
  defineComponent,
  onMounted,
  ref,
  useContext,
  watch,
} from '@nuxtjs/composition-api';

import { useElementHover, useMousePressed } from '@vueuse/core';
import useSwiperAutoplayProgress from '~/composables/useSwiperAutoplayProgress';
import useBreakpoint from '~/composables/useBreakpoint';
import isTouchDevice from '~/helpers/isTouchDevice';
import { hideAllPoppers } from 'floating-vue';
import { getAssetUrl } from '~/composables/useMediaAsset';
import '~/assets/css/floating-vue.css';

const duration = 0.65;

const animations = {
  slideUp: [
    { opacity: 0, yPercent: 50, ease: 'circ' },
    { opacity: 1, yPercent: 0, ease: 'circ', duration },
  ],
  fadeIn: [
    { opacity: 0, ease: 'linear' },
    { opacity: 1, ease: 'linear', duration: 0.5 },
  ],
  fadeInWithBlur: [
    { opacity: 0, backdropFilter: 'blur(0px)', ease: 'linear' },
    { opacity: 1, backdropFilter: 'blur(10px)', ease: 'linear', duration: 0.5 },
  ],
};

const runSlideStartAnimation = ($gsap: GSAP, rootEl: HTMLElement) => {
  const { fadeIn, fadeInWithBlur, slideUp } = animations;
  const activeSlide = rootEl.querySelector('.swiper-slide-active');

  if (!activeSlide) return;

  const markersCanvas = activeSlide!.querySelector('.markers');
  let delay = 0;

  if (markersCanvas) {
    delay += 0.1;
    $gsap.fromTo(markersCanvas, fadeIn[0], fadeIn[1]);

    markersCanvas!
      .querySelectorAll('.marker')
      .forEach((marker: Element) =>
        $gsap.fromTo(marker, fadeInWithBlur[0], fadeInWithBlur[1]),
      );
  }

  activeSlide!
    .querySelectorAll('.title, .text, .button')
    .forEach((selector, i) => {
      delay += 0.1 * (i || 1);
      $gsap.fromTo(selector, slideUp[0], slideUp[1]).delay(delay);
    });
};

const runSlideEndAnimation = ($gsap: GSAP, rootEl: HTMLElement) => {
  const { fadeIn, slideUp } = { ...animations };
  fadeIn[0].duration = 0.1;
  slideUp[0].duration = 0.1;

  rootEl
    .querySelectorAll(
      '.swiper-slide-next .markers, .swiper-slide-prev .markers',
    )
    .forEach((marker: Element) => {
      $gsap.fromTo(marker, fadeIn[1], fadeIn[0]);
    });

  rootEl
    .querySelectorAll(
      '.swiper-slide-next .title, .swiper-slide-prev .title, .swiper-slide-prev .text,  .swiper-slide-next .text,  .swiper-slide-prev .button,  .swiper-slide-next .button',
    )
    .forEach((selector) => $gsap.fromTo(selector, slideUp[1], slideUp[0]));
};

export default defineComponent({
  props: {
    data: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  setup() {
    const { $gsap } = useContext();
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('lg');

    const swiper = ref();
    const pagination = ref();
    const { progress } = useSwiperAutoplayProgress(swiper);
    const isHovered = useElementHover(swiper as any);
    const { pressed } = useMousePressed({ target: swiper as any });

    const runAnimation = (type: string) => {
      (type === 'start' ? runSlideStartAnimation : runSlideEndAnimation)(
        $gsap,
        swiper.value.$el,
      );
    };

    const swiperOptions = {
      speed: 1_000,
      pagination: {
        clickable: true,
        el: '.swiper-banners-pagination',
      },
      navigation: {
        prevEl: '.nav-prev',
        nextEl: '.nav-next',
        disabledClass: 'group-hover:opacity-50 group-hover:hover:opacity-75',
      },
      autoplay: {
        delay: 8_000,
        disableOnInteraction: false,
        waitForTransition: false,
      },
      lazy: {
        enabled: true,
        checkInView: true,
        loadPrevNext: true,
        loadOnTransitionStart: true,
      },
      loop: true,
      slidesPerView: 1,
      observer: true,
      on: {
        activeIndexChange: () => {
          pagination.value
            ?.querySelectorAll('.swiper-pagination-bullet')
            .forEach((el: HTMLElement) => {
              // eslint-disable-next-line no-param-reassign
              el.style.background = 'rgba(255, 255, 255, 0.4)';
            });
        },
        slideChangeTransitionStart: () => {
          hideAllPoppers();
        },
        slideChangeTransitionEnd: () => {
          runAnimation('end');
          runAnimation('start');
        },
      },
    };

    const getCanvasImage = (src: string) =>
      getAssetUrl(src, isDesktop.value ? {} : { height: 600 });

    const getBackgroundImage = (banner: any) => {
      return {
        backgroundImage: `url(${
          isDesktop.value
            ? getAssetUrl(banner.image)
            : getAssetUrl(banner.imageMobile)
        })`,
      };
    };

    const swiperAutoplayToggle = (state: boolean) => {
      if (state) swiper.value?.$swiper.autoplay.stop();
      else swiper.value?.$swiper.autoplay.start();
    };

    watch(isHovered, (newValue) => {
      if (isTouchDevice()) return;
      swiperAutoplayToggle(newValue as any);
    });

    watch(pressed, (newValue) => {
      if (!isTouchDevice()) return;
      swiperAutoplayToggle(newValue as any);
    });

    watch(progress, (newValue) => {
      const activeBullet = pagination.value.querySelector(
        '.swiper-pagination-bullet-active',
      );

      if (activeBullet)
        activeBullet.style.background = `linear-gradient(90deg, #FFFFFF ${newValue}%, rgba(255, 255, 255, 0.4) 0%)`;
    });

    onMounted(() => {
      runAnimation('start');
    });

    const cursorClass = ref('');

    return {
      cursorClass,
      swiper,
      pagination,
      progress,
      swiperOptions,
      isDesktop,
      getCanvasImage,
      getAssetUrl,
      getBackgroundImage,
    };
  },
});
