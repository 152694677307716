import { ref, useContext } from '@nuxtjs/composition-api';

const useRecaptcha = () => {
  const { $recaptcha } = useContext();
  const id = Math.random().toString(36);
  const widgetId = ref();
  const isBusy = ref(false);
  const token = ref('');

  const getToken = () => {
    return token.value;
  };

  const reset = () => {
    isBusy.value = false;
    $recaptcha.reset();
  };

  const init = async () => {
    await $recaptcha.init();

    widgetId.value = $recaptcha.render(id, {
      // @ts-ignore
      sitekey: $recaptcha.siteKey,
    });
  };

  const setToken = (t = '') => {
    token.value = t;
  };
  return {
    id,
    init,
    setToken,
    isBusy,
    getToken,
    reset,
  };
};

export default useRecaptcha;
