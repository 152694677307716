import { render, staticRenderFns } from "./TopbarLocation.vue?vue&type=template&id=297e7a12&scoped=true"
import script from "./TopbarLocation.vue?vue&type=script&lang=ts"
export * from "./TopbarLocation.vue?vue&type=script&lang=ts"
import style0 from "./TopbarLocation.vue?vue&type=style&index=0&id=297e7a12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "297e7a12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiIcon.vue').default,UiSelectOption: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiSelectOption.vue').default,UiSelect: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiSelect.vue').default})
